import * as React from "react"
import { useEffect } from "react";
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero-motion"
import BlockParagraph from "../components/blockParagraph"
import BlockImage from "../components/blockImage"
import SectionTitle from "../components/sectionTitle"
import Testimonials from "../components/testimonials"
import TestimonialItem from "../components/testimonialItem"
import Marquee from '../components/marquee';

import freshlyImage from "../images/freshly.png"
import simpleBundlesImage from "../images/simple-bundles.png"

import CustomerBetterfed from "../images/logos/customer-logo-betterfed.svg"
import CustomerBokksu from "../images/logos/customer-logo-bokksu.svg"
import CustomerEightOunce from "../images/logos/customer-logo-eightounce.svg"
import CustomerGlossier from "../images/logos/customer-logo-glossier.svg"
import CustomerGodiva from "../images/logos/customer-logo-godiva.svg"
import CustomerLoccitane from "../images/logos/customer-logo-loccitane.svg"
import CustomerMarthaStewart from "../images/logos/customer-logo-marthastewart.svg"
import CustomerMasterchef from "../images/logos/customer-logo-masterchef.svg"
import CustomerSennheiser from "../images/logos/customer-logo-sennheiser.svg"

const clientLogos = [

  { src: CustomerMasterchef, alt: "Masterchef" },
  { src: CustomerBokksu, alt: "Bokksu" },
  { src: CustomerBetterfed, alt: "Betterfed" },
  { src: CustomerGodiva, alt: "Godiva" },
  { src: CustomerEightOunce, alt: "Eight Ounce" },
  { src: CustomerGlossier, alt: "Glossier" },
  { src: CustomerSennheiser, alt: "Sennheiser" },
  { src: CustomerLoccitane, alt: "Loccitane" },
  { src: CustomerMarthaStewart, alt: "Martha Stewart" },
];

const IndexPage = () => {

  const pageTitle = "Freshly Commerce"

  return (

    <Layout pageTitle={pageTitle}>
      <Seo title={pageTitle} />

      <Hero
        heroText="Effortless inventory management & traceability"
        heroDesc="Freshly Commerce powers inventory tracking for thousands of growing businesses. Seamlessly manage your inventory—from stocking up to order fulfillment—all under one roof."
      />

      <section className="block block-marquee padding-top-none">
        <div className="block-content g-m-1-13 g-l-4-12"> 
          <Marquee logos={clientLogos} />
        </div>
      </section>

      <section className="block block-section">
        <SectionTitle text="" />

        <BlockParagraph
          blockTitle="Simple Bundles"
          blockContent={
            <>
              Simple Bundles is the fastest way to send order details with
              multi-SKU bundles and kits to 3PLs, WMS, shipping software, and
              other third-party integrations (e.g. ShipStation and Fulfillment by
              Amazon).
              <br />
              <br />
              Simple Bundles breaks down bundle orders to individual SKUs to allow
              real-time inventory syncing and ease of order fulfillment.
            </>
          }
          blockLinkURL="/simple-bundles"
          blockLinkTitle="Learn more"
        />

        <BlockImage
          blockImageSrc={simpleBundlesImage}
          blockImageAlt="Simple Bundles"
          blockImageCaption="Simple Bundles"
        />

        <Testimonials link="https://apps.shopify.com/simple-bundles#reviews?utm_source=freshly-commerce&utm_medium=landing-page">
          <div className="block-content g-m-1-13 g-l-1-6 margin-bottom-medium">
            <TestimonialItem
              desc="This app is as elegant as it can get with bundles. Its functionalities are seamlessly while giving so much curation control to the merchant. To top it off, their team is just pure joy to work with!"
              author="Essano"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-7-12 margin-bottom-medium">
            <TestimonialItem
              desc="It’s a game-changer for our fulfillment process. It allows us to split our kit SKUs into individual components, making it easy to barcode scan during fulfillment."
              author="Battle Garage Racing Service"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-1-6 margin-bottom-medium">
            <TestimonialItem
              desc="I've literally been on the verge of crying happy tears while implementing this app. Now I can keep real inventory numbers and make my upcoming switch to a 3PL much simpler."
              author="Genius Lab Gear"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-7-12 margin-bottom-medium">
            <TestimonialItem
              desc="It’s revolutionized the way we operate! It’s incredibly easy to use and help us manage our stock. We can now easily create exciting bundles with just one click, and track our stock at the same time."
              author="Liquor Loot"
            />
          </div>
        </Testimonials>
        
        <SectionTitle text="Products" />
        <BlockParagraph
          id="products"
          blockTitle="Freshly Inventory"
          blockContent={
            <>
              Freshly is a batch tracking and traceability software designed for
              suppliers, retailers, and others players in the distribution chain.
              Get real-time visibility into your inventory to enable strategic
              decision-making.
              <br />
              <br />
              Batch or lot tracking is essential for products such as food and
              beverages, cosmetics, medical supplies, and other products that
              require quality managment and traceability.
            </>
          }
          blockLinkURL="/freshly-inventory"
          blockLinkTitle="Learn more"
        />

        <BlockImage
          blockImageSrc={freshlyImage}
          blockImageAlt="Freshly Inventory"
          blockImageCaption="Freshly Inventory"
        />

        <Testimonials link="https://apps.shopify.com/freshly#reviews?utm_source=freshly-commerce&utm_medium=landing-page">
          <div className="block-content g-m-1-13 g-l-1-6 margin-bottom-medium">
            <TestimonialItem
              desc="Freshly has been a godsend. The batch reporting is excellent should we ever have a recall. Best of all, it informs us before our batches expire so we can put them on sale. What an amazing app that is only outdone by their customer service."
              author="Premium eJuice"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-7-12 margin-bottom-medium">
            <TestimonialItem
              desc="Freshly is intuitive with smart, automated features. Definitely a must-have if you're selling products with batches and expiry dates. Inventory batch bulk updates are quick & easy with CSV uploads."
              author="Mum's Medicine Cabinet"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-1-6 margin-bottom-medium">
            <TestimonialItem
              desc="Freshly helps us manage inventory expiration dates and speed up the sales of slow-moving stock. The packing slips allows our staff pack orders using first-in, first-out. The support is top notch!"
              author="Food Craft"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-7-12 margin-bottom-medium">
            <TestimonialItem
              desc="Freshly has made batch tracking easy and efficient for our medical devices. The app showcases expiration dates, offers promotions on expiring batches, and removes inventory that's close to expiry. Highly recommended!"
              author="Lumiquick Diagnostics Store"
            />
          </div>
        </Testimonials>

        <SectionTitle text="" />

        <BlockParagraph
          id="about"
          blockTitle="About us"
          blockContent={
            <>
              We created Freshly Commerce in 2020 to help business owners bring
              their shops online during the pandemic. Since then, we’ve grown to
              serve over 15,000 customers worldwide. Our team is based in Waterloo,
              Canada.
              <br /> <br />
              Freshly Commerce was founded by{" "}
              <a href="https://www.basilkhan.ca/" target="_blank" rel="noreferrer">
                Basil
              </a>{" "}
              and{" "}
              <a href="https://www.linkedin.com/in/lichenz/" target="_blank" rel="noreferrer">
                Lichen
              </a>{" "}
              who love nothing more than helping entrepreneurs build successful
              businesses. We focus on building high quality tools, designing
              seamless experiences, and providing world-class support.
            </>
          }
        />

        <BlockParagraph
          blockTitle="Awards"
          blockContent={
            <>
              <a
                href="https://www.fda.gov/food/new-era-smarter-food-safety/meet-winners-fdas-low-or-no-cost-food-traceability-challenge"
                target="_blank"
                rel="noreferrer"
              >
                FDA's New Era of Smarter Food Safety Traceability Award
              </a>
              <br />
              <br />
              <a
                href="https://www.shopify.ca/partners/blog/shopify-app-challenge-winners"
                target="_blank"
                rel="noreferrer"
              >
                Shopify's App Challenge Award
              </a>{" "}
            </>
          }
        />

        <BlockParagraph
          id="contact"
          blockTitle="Get in touch"
          blockContent={
            <>
              For general queries, please email us at{" "}
              <a href="mailto:hello@getfreshly.io">
                hello@getfreshly.io
              </a>{" "}
              and let us know how we can help—we’ll get back to you as soon as we can.
            </>
          }
        />

        {/* <List listTitle="Unordered List">
          <ListItem
            title="List Item Green"
            titleLink="#"
            desc="List Item Green Desc"
            year="2022"
            status="green"
          />
          <ListItem
            title="List Item Yellow"
            titleLink="#"
            desc="List Item Yellow Desc"
            year="2022"
            status="yellow"
          />
          <ListItem
            title="List Item Red"
            titleLink="#"
            desc="List Item Red Desc"
            year="2022"
            status="red"
          />
        </List> */}
      </section>
    </Layout>

  )

}

export default IndexPage
