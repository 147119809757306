import React, { useState, useEffect } from 'react';
import Marquee from 'react-marquee-slider';
import times from 'lodash/times';

const MarqueeComponent = ({ logos }) => {
  const [velocity, setVelocity] = useState(20);

  useEffect(() => {
    // Check if window is defined (so if we're in the browser and not on the server)
    if (typeof window !== 'undefined') {
      setVelocity(window.innerWidth <= 768 ? 10 : 20);  // Adjust this value as needed
    }
  }, []);

  return (
    <Marquee velocity={velocity} resetAfterTries={200} minScale={1} resetOnHover>
      {times(logos.length, Number).map(id => (
        <img key={`img-${id}`} src={logos[id].src} alt={logos[id].alt} />
      ))}
    </Marquee>
  );
};


export default MarqueeComponent;
