import * as React from "react"
import PropTypes from "prop-types"
const Testimonials = ({ children, link }) => {
  return (
    <div className="block-content g-m-1-13 g-l-4-12 g-4-12 grid">
      {children}
      <div className="block-content g-m-1-13 g-l-7-10">
        <p className="t-18">
          <a href={link} target="_blank" rel="noreferrer">
            Read more reviews
          </a>{" "}
          ⟶
        </p>
      </div>
    </div>
  )
}

Testimonials.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
}

export default Testimonials
