import * as React from "react"
import Button from "./button"
import { motion, useViewportScroll } from "framer-motion"
import classNames from "classnames"

const parentAni = {
  initial: "visible",
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      duration: 1.5,
      ease: "easeOut",
      delay: 1.2,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "exit",
    },
  },
}

const childAni = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

const mouseVariant = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -25 },
}

const Hero = ({
  heroText,
  heroDesc,
  heroButtonLinkPrimary,
  heroButtonTextPrimary,
  heroButtonLinkSecondary,
  heroButtonTextSecondary,
  isVisible,
  extraClasses
}) => {

  const { scrollY } = useViewportScroll();
  const [mouseHidden, setMouseHidden] = React.useState(false);

  const updateMouse = () => {
    if (scrollY?.current > 150) {
      setMouseHidden(true);
    } else {
      setMouseHidden(false);
    }
  }

  React.useEffect(() => {
    return scrollY.onChange(() => updateMouse());
  }, [scrollY]);

  const handleClick = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <motion.section 
      className={classNames("block block-hero", extraClasses)}
      initial={{ paddingBottom: "36rem" }}
      animate={{ paddingBottom: "8rem" }}
      transition={{
        delay: 3,
        duration: 1.5,
        ease: "easeInOut"
      }}
    >
      <motion.div
        className="block-content g-m-1-13 g-l-4-12 g-4-12"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={parentAni}
      >
        {heroText && (
          <motion.h2 className="block-header" variants={childAni}>
            {heroText}
          </motion.h2>
        )}
        {heroDesc && (
          <motion.p className="block-post t-36" variants={childAni}>
            {heroDesc}
          </motion.p>
        )}
        <motion.div className="hide-mq-tablet mouse"
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
          animate={mouseHidden ? "hidden" : "visible"}
          variants={mouseVariant}
        >
          <a
            onClick={handleClick}
            style={{
              height: "100%",
              width: "100%",
              opacity: 0,
              position: "absolute",
            }}
          >
            LINK
          </a>
        </motion.div>
        {(heroButtonLinkPrimary || heroButtonLinkSecondary) && (
          <motion.div className="button-group" variants={childAni}>
            {heroButtonLinkPrimary &&
              <Button buttonClass="primary" buttonLink={heroButtonLinkPrimary} buttonText={heroButtonTextPrimary} />
            }
            {heroButtonLinkSecondary &&
              <Button buttonClass="secondary" buttonLink={heroButtonLinkSecondary} buttonText={heroButtonTextSecondary} />
            }
          </motion.div>
        )}
      </motion.div>
    </motion.section>
  )
}

export default Hero
