import * as React from 'react'
import starIcon from "../images/icons/star.png"

const TestimonialItem = ({ desc, author }) => {
  return (
    <div>
      {/* <p className="t-24">★★★★★</p> */}
      <div className="margin-bottom-medium">
        {Array.from({ length: 5 }, (_x) => <><img src={starIcon} alt="5 star" />{" "}</>)}
      </div>
      <p className="t-18">
        {desc}
      </p>
      <p className="t-18 t-subdued">{author}</p>
    </div>
  )
}
export default TestimonialItem
