import * as React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const Block = ({
  blockTitle,
  blockContent,
  blockLinkURL,
  blockLinkTitle,
  id,
  extraClasses,
}) => (
  <div className={classNames("block-content g-m-1-13 g-l-4-12 g-4-12", extraClasses)} id={id}>
    {blockTitle && <h3 className="t-36 block-content-title">{blockTitle}</h3>}
    <p className="t-36">
      {blockContent}&nbsp;
      {blockLinkURL ? (
        <>
          <a href={blockLinkURL} rel="noreferrer">
            {blockLinkTitle}
          </a>
          .
        </>
      ) : null}
    </p>
  </div>
)

Block.propTypes = {
  blockTitle: PropTypes.string,
  blockContent: PropTypes.string,
  blockLinkURL: PropTypes.string,
  blockLinkTitle: PropTypes.string,
  id: PropTypes.string,
  extraClasses: PropTypes.string,
}

Block.defaultProps = {
  blockTitle: ``,
  blockContent: ``,
  blockLinkURL: ``,
  blockLinkTitle: ``,
  id: ``,
  extraClasses: ``,
}

export default Block
